import * as React from 'react';
import { Router } from '@reach/router';
import ViewCollection from '../components/ViewCollection';
import G3TokenGateProvider from '../components/Dashboard/contexts/G3TokenGateContext';

export default () => {
    return (
        <G3TokenGateProvider>
            <Router basepath="/collection">
                <ViewCollection
                    /*@ts-ignore*/
                    path="/:chain/:contractAddress"
                    styles={{
                        marginTop: ['0rem', '5rem'],
                    }}
                />
            </Router>
        </G3TokenGateProvider>
    );
};
