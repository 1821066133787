import React from 'react';
import {Box, Flex, Text, useColorModeValue} from '@chakra-ui/react';
import useGenverseContract from '../../../../hooks/useGenverseContract';
import TokenMarketPlaceData from '../../../ViewNFT/components/TokenMarketPlaceData';
import { GenverseTokenType } from '../../../../../../types';
import G3LogoSpinner from '../../../general/G3LogoSpinner';

export default ({
    chain,
    contractAddress,
}: {
    chain: string;
    contractAddress: string;
}) => {
    const { genverseContract, loading } = useGenverseContract({
        chain,
        contractAddress,
    });

    const stats = [
        {
            value: genverseContract?.tokensCount,
            label: 'Supply',
        },

        {
            value: genverseContract?.holders,
            label: 'Owners',
        },

        {
            value:
                !!genverseContract?.tokensCount && !!genverseContract?.holders
                    ? `${(
                          (genverseContract?.holders * 100) /
                          genverseContract.tokensCount
                      ).toFixed(2)}%`
                    : '',
            label: 'Owners %',
        },

        !!genverseContract?.holders &&
            genverseContract?.tokensCount && {
                value: (
                    genverseContract?.tokensCount / genverseContract?.holders
                ).toFixed(2),
                label: 'Avg. Owned',
            },
    ].filter(Boolean);

    return (
        <Flex direction="column" align="center" justify="center">
            <Flex align="center" justify={'center'} flexWrap="wrap">
                {stats.map(({ value, label }: any) => (
                    <Flex
                        minWidth={['40%', '8rem']}
                        direction="column"
                        justify="center"
                        align="center"
                        background={useColorModeValue(
                            'rgba(0, 0, 0, 0.1)',
                            'rgba(218, 175, 255, 0.05)'
                        )}
                        backdropFilter={useColorModeValue(
                            'brightness(100%)',
                            'brightness(200%)'
                        )}
                        borderRadius={'0.375rem'}
                        padding=".5rem 1rem"
                        margin={['0 .5rem 1rem .5rem', '0 .5rem']}
                    >
                        <Flex justify="center" align="center">
                            {!loading ? (
                                <Text fontSize="1.6rem" fontWeight="700">
                                    {value?.toLocaleString()}
                                </Text>
                            ) : (
                                <G3LogoSpinner imageProps={{ w: '1rem' }} />
                            )}
                        </Flex>
                        <Text fontSize={'.9rem'}>{label}</Text>
                    </Flex>
                ))}
            </Flex>

            <Flex
                align="center"
                justify={'center'}
                flexWrap="wrap"
                marginTop="1rem"
            >
                <TokenMarketPlaceData
                    token={
                        {
                            chain,
                            tokenAddress: contractAddress,
                        } as GenverseTokenType
                    }
                    days={1}
                    periodLabel="(24 Hours)"
                    style={{ marginBottom: '1rem' }}
                />
                <Box w="1rem" />
                <TokenMarketPlaceData
                    token={
                        {
                            chain,
                            tokenAddress: contractAddress,
                        } as GenverseTokenType
                    }
                    days={7}
                    periodLabel="(7 Days)"
                    style={{ marginBottom: '1rem' }}
                />
                <Box w="1rem" />
                <TokenMarketPlaceData
                    token={
                        {
                            chain,
                            tokenAddress: contractAddress,
                        } as GenverseTokenType
                    }
                    days={28}
                    periodLabel="(28 Days)"
                    style={{ marginBottom: '1rem' }}
                />
            </Flex>
        </Flex>
    );
};
