import React from 'react';
import {Box, Flex, Heading, Text, useColorModeValue} from '@chakra-ui/react';
import useGenverseContract from '../../../../hooks/useGenverseContract';
import { Link } from 'gatsby';
import { startCase } from 'lodash';
import G3LogoSpinner from '../../../general/G3LogoSpinner';

export default ({
    chain,
    contractAddress,
}: {
    chain: string;
    contractAddress: string;
}) => {
    const { genverseContract, loading } = useGenverseContract({
        chain,
        contractAddress,
    });

    return (
        <Flex
            height={'100%'}
            direction={'column'}
            justify={'flex-start'}
            maxHeight={'70vh'}
            width={'100%'}
            minWidth="8rem"
            background={useColorModeValue(
                'rgba(0, 0, 0, 0.1)',
                'rgba(218, 175, 255, 0.05)'
            )}
            backdropFilter={useColorModeValue(
                'brightness(100%)',
                'brightness(200%)'
            )}
            borderRadius={'0.375rem'}
            padding=".5rem 1rem"
            align="center"
            overflowY="scroll"
        >
            <Heading
                size="md"
                textAlign={['center', 'left']}
                fontWeight="300"
                margin="1rem 0"
            >
                {`Traits Categories ${
                    genverseContract?.traitRarity?.totalTraits > 0
                        ? `(${genverseContract.traitRarity.totalTraits})`
                        : ''
                }`}
            </Heading>
            {loading && <G3LogoSpinner containerProps={{ h: '20vh' }} />}

            {!loading &&
                genverseContract?.traitRarity?.attributesRarity?.length > 0 &&
                genverseContract.traitRarity.attributesRarity.map(
                    ({
                        name,
                        traitCounts,
                    }: {
                        name: string;
                        traitCounts: any;
                    }) => (
                        <Flex
                            maxWidth="100%"
                            width="100%"
                            direction="column"
                            border={useColorModeValue(
                                'solid 1px rgba(0, 0, 0, 0.2)',
                                'solid 1px rgba(255, 255, 255, 0.2)'
                            )}
                            borderRadius="0.375rem"
                            padding="0.5rem 0"
                            marginBottom="1rem"
                        >
                            <Text padding=".5rem 0 0rem 1rem">{`${startCase(
                                name
                            )}${
                                traitCounts?.length > 0
                                    ? ` (${traitCounts?.length})`
                                    : ''
                            }`}</Text>
                            <Flex
                                direction="row"
                                wrap="wrap"
                                align="center"
                                justify="flex-start"
                                maxWidth="100%"
                                width="100%"
                                overflowX="scroll"
                                padding="1rem"
                            >
                                {traitCounts?.map(
                                    (trait: {
                                        name: string;
                                        count: number;
                                    }) => (
                                        <Link
                                            to={`/collection/${chain}/${contractAddress}?tab=1&traitFilters=${JSON.stringify(
                                                {
                                                    [name]: trait.name,
                                                }
                                            )}`}
                                        >
                                            <Flex
                                                borderRadius="0.375rem"
                                                backgroundColor={useColorModeValue(
                                                    'rgba(0, 0, 0, 0.2)',
                                                    'rgba(255, 255, 255, 0.2)'
                                                )}
                                                margin="0 .5rem .5rem 0"
                                            >
                                                <Box padding="0 0.25rem 0 0.5rem">
                                                    <Text
                                                        fontSize=".8rem"
                                                        whiteSpace="nowrap"
                                                    >
                                                        {`${startCase(
                                                            trait?.name
                                                        )}`}
                                                    </Text>
                                                </Box>
                                                <Box padding="0 0.5rem 0 0.25rem">
                                                    <Text
                                                        fontSize=".8rem"
                                                        whiteSpace="nowrap"
                                                    >
                                                        {`${trait?.count}x`}
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </Link>
                                    )
                                )}
                            </Flex>
                        </Flex>
                    )
                )}
        </Flex>
    );
};
