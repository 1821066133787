import { useQuery, WatchQueryFetchPolicy } from '@apollo/react-hooks';
import SearchContractTokensQuery from '../../api/searchContractTokens.query.gql';
import {
    GenverseContractSearchFilters,
    GenverseTokenType,
} from '../../../types';
import queryString from 'query-string';
// import { keys } from 'lodash';
import { useEffect, useState } from 'react';

export default ({
    chain,
    contractAddress,
    searchFilters, // NOTE: You must provide a search filter or this function does not search
    fetchPolicy = 'cache-first',
}: {
    chain: string;
    contractAddress: string;
    searchFilters: GenverseContractSearchFilters;
    fetchPolicy?: WatchQueryFetchPolicy;
}) => {
    // Whenever this is called it triggers a search
    // e.g. ?Background=Midnight%20Genesis
    const [searchQueryParams, setSearchQueryParams] = useState('');

    const getSearchFiltersAsQueryString = () =>
        `${queryString.stringify({
            ...(searchFilters?.tokenId
                ? { tokenId: searchFilters.tokenId }
                : {}),
            ...searchFilters?.traitFilters,
        })}`;

    // When search filters change we update the search
    useEffect(() => {
        setSearchQueryParams(getSearchFiltersAsQueryString());
    }, [searchFilters]);

    const getSearchQueryStringWithCursor = (cursor = null as string | null) => {
        const params = searchQueryParams
            ? queryString.parse(searchQueryParams)
            : {};
        return `${queryString.stringify({
            ...params,
            ...(cursor ? { cursor } : {}),
        })}`;
    };

    const { data, loading, error, fetchMore } = useQuery(
        SearchContractTokensQuery,
        {
            variables: {
                chain,
                contractAddress,
                searchQueryParams: `?${searchQueryParams}`,
            },
            fetchPolicy,
        }
    );

    const genverseSearchData = !loading && data?.searchContractTokens?.data;
    const [tokens, setTokens] = useState(null as any[] | null);

    useEffect(() => {
        if (!loading) {
            setTokens(genverseSearchData?.tokens || []);
        }
    }, [genverseSearchData, loading]);

    const hasNextPage = !!genverseSearchData?.cursor;

    const loadNextPage = async () => {
        if (hasNextPage && !loading) {
            await fetchMore({
                variables: {
                    chain,
                    contractAddress,
                    searchQueryParams: `?${getSearchQueryStringWithCursor(
                        genverseSearchData?.cursor
                    )}`,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult?.searchContractTokens?.data)
                        return prevResult;

                    // Merge previous tokens & cursor
                    return {
                        searchContractTokens: {
                            data: {
                                ...prevResult?.searchContractTokens?.data,
                                cursor: fetchMoreResult?.searchContractTokens
                                    ?.data?.cursor,
                                tokens: [
                                    ...prevResult?.searchContractTokens?.data
                                        ?.tokens,
                                    ...fetchMoreResult?.searchContractTokens
                                        ?.data?.tokens,
                                ],
                            },
                        },
                    };
                },
            });
        }
    };

    return {
        searchQueryParams,
        tokens,
        loading,
        error,
        hasNextPage,
        loadNextPage,
    } as {
        searchQueryParams: string;
        tokens: GenverseTokenType[] | null;
        loading: boolean;
        error: any;
        hasNextPage: boolean;
        loadNextPage: () => void;
    };
};
