import React from 'react';
import { AspectRatio, Flex, Heading, Image, Text } from '@chakra-ui/react';
import useGenverseContract from '../../../../hooks/useGenverseContract';
import useOpenSeaContractData from '../../../../hooks/useOpenSeaContractData';
import CopyClipboardWrapper from '../../../general/CopyClipboardWrapper';
import { GenverseTokenType } from '../../../../../../types';
import ExternalLinks from '../../../general/ExternalLinks';

export default ({
    chain,
    contractAddress,
    showViewTokensCTA,
}: {
    chain: string;
    contractAddress: string;
    showViewTokensCTA?: boolean;
}) => {
    const { genverseContract, loading } = useGenverseContract({
        chain,
        contractAddress,
    });

    const { loading: openSeaLoading, data: openSeaData } =
        useOpenSeaContractData(contractAddress) as any;

    const openSeaImageUrl =
        // @ts-ignore
        openSeaData?.collection?.large_image_url ||
        // @ts-ignore
        openSeaData?.collection?.image_url ||
        // @ts-ignore
        openSeaData?.image_url;

    const collectionName =
        genverseContract?.name ||
        genverseContract?.symbol ||
        openSeaData?.collection?.name;

    const collectionSymbol = genverseContract?.name
        ? genverseContract?.symbol
            ? genverseContract.symbol
            : openSeaData?.symbol
        : null;

    return (
        <Flex
            direction={['column', 'row']}
            justify={'center'}
            align="center"
            margin="0 2rem"
        >
            {!loading && !openSeaLoading && !!openSeaImageUrl && (
                <AspectRatio
                    width="8rem"
                    ratio={1}
                    cursor={'pointer'}
                    borderRadius={'50%'}
                    overflow="hidden"
                >
                    <Image
                        key={openSeaImageUrl}
                        height="100%"
                        width="100%"
                        objectPosition="center"
                        objectFit="contain"
                        border={0}
                        src={openSeaImageUrl}
                        // alt={alt}
                        // onError={(e) => addError('image')}
                        // onLoad={() => setLoading(false)}
                        loading="lazy"
                    />
                </AspectRatio>
            )}
            <Flex
                // flex={1}
                direction="column"
                align={['center', 'flex-start']}
                marginLeft={[0, '2rem']}
                marginTop={['1rem', 0]}
            >
                <Heading
                    size="lg"
                    fontWeight="900"
                    textAlign={['center', 'left']}
                >
                    {collectionName}
                </Heading>
                {collectionSymbol && (
                    <Heading
                        size="md"
                        textAlign={['center', 'left']}
                        fontWeight="300"
                        marginTop=".5rem"
                    >
                        {genverseContract?.name ? genverseContract?.symbol : ''}
                    </Heading>
                )}
                <CopyClipboardWrapper copyText={contractAddress}>
                    <Text fontSize="0.7rem" marginTop=".5rem">
                        {contractAddress}
                    </Text>
                </CopyClipboardWrapper>
                <Flex
                    align="center"
                    justify={['center', 'flex-start']}
                    marginTop="0.5rem"
                >
                    <ExternalLinks
                        showViewTokensCTA={showViewTokensCTA}
                        token={
                            {
                                chain,
                                tokenAddress: contractAddress,
                            } as GenverseTokenType
                        }
                    />
                </Flex>
            </Flex>
        </Flex>
    );
};
