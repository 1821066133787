import React, { useEffect } from 'react';
import './style.css';
import 'react-image-lightbox/style.css';
import Layout from '../base/Layout';
import ContractHeader from './components/ContractHeader';
import ContractStats from './components/ContractStats';
import {
    Box,
    Grid,
    GridItem,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@chakra-ui/react';
import ContractHoldersTable from './components/ContractHoldersTable';
import ContractHoldersDistribution from './components/ContractHoldersDistribution';
import ContractTraitRaritiesTable from './components/ContractTraitRaritiesTable';
import useGenverseContract from '../../hooks/useGenverseContract';
import ViewCollectionTokens from './components/ViewCollectionTokens';
import Container from '../base/Container';
import { NumberParam, useQueryParams } from 'use-query-params';
import G3LogoSpinner from '../general/G3LogoSpinner';
import G3Tab from '../general/G3Tab';

export const QUERY_PARAM_TAB = 'tab';
export default ({
    chain,
    contractAddress,
    styles,
}: {
    asModal: boolean;
    showModal: boolean;
    onClose: () => void;
    chain: string;
    contractAddress: string;
    styles?: {};
}) => {
    const [queryParams, setQueryParams] = useQueryParams({
        [QUERY_PARAM_TAB]: NumberParam,
    });

    const { genverseContract, loading } = useGenverseContract({
        chain,
        contractAddress,
        fetchPolicy: 'network-only',
    });

    const [tabIndex, setTabIndex] = React.useState(
        queryParams?.[QUERY_PARAM_TAB] || 0
    );

    useEffect(() => {
        if (tabIndex >= 0 && queryParams?.[QUERY_PARAM_TAB] !== tabIndex)
            setQueryParams({
                [QUERY_PARAM_TAB]: tabIndex,
            });
    }, [tabIndex]);

    useEffect(() => {
        if (
            (queryParams?.[QUERY_PARAM_TAB] as number) >= 0 &&
            queryParams?.[QUERY_PARAM_TAB] !== tabIndex
        ) {
            setTabIndex(queryParams[QUERY_PARAM_TAB] as number);
        }
    }, [queryParams]);

    return (
        <Layout
            noContainer
            showNavBarLogo
            showSearchBar
            showNavLinks
            showConnectButton
            styles={styles}
        >
            <ContractHeader chain={chain} contractAddress={contractAddress} />
            {loading && (
                <G3LogoSpinner
                    containerProps={{
                        h: '20vh',
                    }}
                />
            )}
            {!!genverseContract && !loading && (
                <>
                    <Box marginTop="2rem">
                        <ContractStats
                            chain={chain}
                            contractAddress={contractAddress}
                        />
                    </Box>

                    <Tabs
                        variant="soft-rounded"
                        colorScheme="whiteAlpha"
                        align="center"
                        marginTop="2rem"
                        index={tabIndex}
                        defaultIndex={tabIndex}
                        onChange={(index) => setTabIndex(index)}
                    >
                        <TabList>
                            <G3Tab>Stats & Insights</G3Tab>
                            <G3Tab>NFTs</G3Tab>
                        </TabList>

                        <TabPanels>
                            <TabPanel
                                padding={['1rem 0.5rem 0 0.5rem', '1rem 0 0 0']}
                            >
                                <Container largeContainer>
                                    <Grid
                                        marginTop={['1rem', '1rem']}
                                        width="100%"
                                        gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr));"
                                        gap="1rem"
                                    >
                                        <GridItem>
                                            <ContractHoldersTable
                                                chain={chain}
                                                contractAddress={
                                                    contractAddress
                                                }
                                            />
                                        </GridItem>
                                        <GridItem>
                                            <ContractHoldersDistribution
                                                chain={chain}
                                                contractAddress={
                                                    contractAddress
                                                }
                                            />
                                        </GridItem>
                                        <GridItem>
                                            <ContractTraitRaritiesTable
                                                chain={chain}
                                                contractAddress={
                                                    contractAddress
                                                }
                                            />
                                        </GridItem>
                                    </Grid>
                                </Container>
                            </TabPanel>
                            <TabPanel padding={'1rem 0 0 0'}>
                                <ViewCollectionTokens
                                    chain={chain}
                                    contractAddress={contractAddress}
                                />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </>
            )}
        </Layout>
    );
};
