import React from 'react';
import {
    Box,
    Flex,
    Heading,
    Table,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from '@chakra-ui/react';
import useGenverseContract from '../../../../hooks/useGenverseContract';
import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    RadialLinearScale,
    registerables,
    Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { keys } from 'lodash';
import G3LogoSpinner from '../../../general/G3LogoSpinner';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    ArcElement,
    RadialLinearScale,
    ...registerables
);

export default ({
    chain,
    contractAddress,
}: {
    chain: string;
    contractAddress: string;
}) => {
    const { genverseContract, loading } = useGenverseContract({
        chain,
        contractAddress,
    });
    const sortOrder = ['1', '2 - 3', '4 - 10', '11 - 25', '26-50', '50+'];
    const sortedKeys = genverseContract?.holdersDistribution
        ? keys(genverseContract.holdersDistribution).sort(
              (a, b) => sortOrder.indexOf(a) - sortOrder.indexOf(b)
          )
        : [];

    const dataValues = sortedKeys
        ? sortedKeys
              ?.map((key) => genverseContract.holdersDistribution[key])
              // Round to 2 DP
              ?.map(
                  ({ percentage }) =>
                      Math.round((percentage + Number.EPSILON) * 100) / 100
              )
        : [];

    // @ts-ignore
    const data = !!genverseContract?.holdersDistribution && {
        legend: {
            labels: {},
        },
        title: {
            display: true,
            fontColor: 'blue',
            text: 'Holders Distribution',
        },
        labels: sortedKeys,
        datasets: [
            {
                data: dataValues,
                color: useColorModeValue('#31298A', 'white'),
                backgroundColor: useColorModeValue(
                    'rgba(49,41,138,0.35)',
                    'rgba(255, 255, 255, 0.35)'
                ),
                borderColor: useColorModeValue(
                    'rgba(49,41,138,0.7)',
                    'rgba(255, 255, 255, 0.7)'
                ),
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                enabled: true,
            },
        },
        scales: {
            x: {
                grid: {
                    color: useColorModeValue(
                        'rgba(0, 0, 0, 0.1)',
                        'rgba(255, 255, 255, 0.1)'
                    ),
                },
                ticks: {
                    color: useColorModeValue('black', 'white'),
                },
            },
            y: {
                grid: {
                    color: useColorModeValue(
                        'rgba(0, 0, 0, 0.1)',
                        'rgba(255, 255, 255, 0.1)'
                    ),
                },
                ticks: {
                    color: useColorModeValue('black', 'white'),
                    precision: 0,
                },
            },
        },
    };

    return (
        <Flex
            direction="column"
            justify={'flex-start'}
            align="center"
            height={'100%'}
            width={'100%'}
            minWidth="8rem"
            background={useColorModeValue(
                'rgba(0, 0, 0, 0.1)',
                'rgba(218, 175, 255, 0.05)'
            )}
            backdropFilter={useColorModeValue(
                'brightness(100%)',
                'brightness(200%)'
            )}
            borderRadius={'0.375rem'}
            padding=".5rem 1rem"
            flexWrap="wrap"
            overflow="hidden"
        >
            <Heading
                size="md"
                textAlign={['center', 'left']}
                fontWeight="300"
                margin="1rem 0"
            >
                {`Holders Distribution`}
            </Heading>
            {loading && <G3LogoSpinner containerProps={{ h: '20vh' }} />}
            {!loading && (
                <Box padding="1rem" width="100%">
                    {!!data && (
                        <Bar data={data as any} options={chartOptions} />
                    )}
                    <Table size="lg" variant="unstyled" marginTop="1rem">
                        <Thead>
                            <Tr>
                                {['Amount Owned', 'Owners']?.map((label) => (
                                    <Th
                                        key={`th-${label}`}
                                        color={useColorModeValue(
                                            'rgba(0, 0, 0, 0.5)',
                                            'rgba(255, 255, 255, 0.5)'
                                        )}
                                        fontSize=".8rem"
                                        px=".5rem"
                                        py=".5rem"
                                    >
                                        {label}
                                    </Th>
                                ))}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {!!sortedKeys?.length &&
                                sortedKeys?.map((key, keyIndex) => (
                                    <Tr
                                        width="50%"
                                        background={
                                            keyIndex % 2 === 0
                                                ? useColorModeValue(
                                                      'rgba(0, 0, 0, 0.1)',
                                                      'rgba(0, 0, 0, 0.3)'
                                                  )
                                                : 'transparent'
                                        }
                                    >
                                        {[key, dataValues[keyIndex]]?.map(
                                            (label, index) => (
                                                <Td
                                                    key={`td-${label}`}
                                                    color={useColorModeValue(
                                                        'black',
                                                        'white'
                                                    )}
                                                    fontSize={[
                                                        '0.9rem',
                                                        '1rem',
                                                    ]}
                                                    px=".5rem"
                                                    py=".5rem"
                                                >
                                                    {`${label}${
                                                        index === 1 ? '%' : ''
                                                    }`}
                                                </Td>
                                            )
                                        )}
                                    </Tr>
                                ))}
                        </Tbody>
                    </Table>
                </Box>
            )}
        </Flex>
    );
};
