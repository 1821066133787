import React, { useEffect, useState } from 'react';
import './style.css';
import 'react-image-lightbox/style.css';
import PageNFTGrid from '../../../PageNFTGrid';
import {
    FiltersBarsProps,
    GenverseContractSearchFilters,
    GenverseTokenType,
} from '../../../../../../types';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Select,
    Text,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import useSearchGenverseContract from '../../../../hooks/useSearchGenverseContract';
import useGenverseContract from '../../../../hooks/useGenverseContract';
import { keys, parseInt } from 'lodash';
import { JsonParam, NumberParam, useQueryParams } from 'use-query-params';
import FiltersSideBar from './components/FiltersSideBar';
import G3LogoSpinner from '../../../general/G3LogoSpinner';
import G3BottomSheet from '../../../general/G3BottomSheet';

export const QUERY_PARAM_TOKEN_ID = 'tokenId';
export const QUERY_PARAM_DISPLAY_GRID_ITEMS = 'displayGridItems';
export const QUERY_PARAM_TRAIT_FILTERS = 'traitFilters';

const FiltersBar = ({
    currentTokenId,
    onSearchTokenId,
    traitAttributes,
    selectedTraits,
    onSelectTrait,
}: FiltersBarsProps) => (
    <FiltersSideBar
        currentTokenId={currentTokenId}
        onSearchTokenId={onSearchTokenId}
        traitAttributes={traitAttributes}
        selectedTraits={selectedTraits}
        onSelectTrait={onSelectTrait}
    />
);

export default ({
    chain,
    contractAddress,
    styles,
}: {
    chain: string;
    contractAddress: string;
    styles?: any;
}) => {
    const [queryParams, setQueryParams] = useQueryParams({
        [QUERY_PARAM_TOKEN_ID]: NumberParam,
        [QUERY_PARAM_DISPLAY_GRID_ITEMS]: NumberParam,
        [QUERY_PARAM_TRAIT_FILTERS]: JsonParam,
    });

    const [searchFilters, setSearchFilters] = useState(
        {} as GenverseContractSearchFilters
    );
    const [isMobile] = useMediaQuery('(max-width: 768px)');

    let gridItemDisplayCount = queryParams.displayGridItems || 5;

    if (isMobile) {
        if (gridItemDisplayCount > 3) {
            gridItemDisplayCount = 2; // Fallback as this size is not enabled for mobile
        }
    }

    const { genverseContract } = useGenverseContract({
        chain,
        contractAddress,
        fetchPolicy: 'network-only',
    });

    // Synchronise search filters with query params
    useEffect(() => {
        setSearchFilters({
            ...(queryParams?.tokenId
                ? { tokenId: queryParams?.[QUERY_PARAM_TOKEN_ID] }
                : {}),
            traitFilters: queryParams?.[QUERY_PARAM_TRAIT_FILTERS],
        } as GenverseContractSearchFilters);
    }, [queryParams]);

    const { tokens, hasNextPage, loadNextPage, loading } =
        useSearchGenverseContract({
            chain,
            contractAddress,
            searchFilters,
        });

    const onSelectDisplayItems = (displayItemCount: string) =>
        setQueryParams({
            [QUERY_PARAM_DISPLAY_GRID_ITEMS]: parseInt(displayItemCount),
        });

    const onToggleTraitFilter = (
        traitType: string,
        traitValue: string,
        isChecked: boolean
    ) => {
        const currentTraitFilters =
            queryParams?.[QUERY_PARAM_TRAIT_FILTERS] || {};

        if (isChecked) {
            currentTraitFilters[traitType] = traitValue;
        } else {
            delete currentTraitFilters?.[traitType];
        }

        setQueryParams({
            // @ts-ignore
            [QUERY_PARAM_TRAIT_FILTERS]: currentTraitFilters,
        });
    };

    const activeFiltersCount =
        (keys(queryParams?.traitFilters)?.length || 0) +
        (queryParams?.tokenId ? 1 : 0);

    const filtersBarProps = {
        currentTokenId: queryParams?.[QUERY_PARAM_TOKEN_ID] as number,
        onSearchTokenId: (tokenId: number) => {
            setQueryParams({
                [QUERY_PARAM_TOKEN_ID]: tokenId || undefined,
            });
            if (isMobile) setShowFiltersSheet(false);
        },

        traitAttributes: genverseContract?.traitRarity?.attributesRarity,

        selectedTraits: searchFilters?.traitFilters,

        onSelectTrait: (
            traitName: string,
            traitValue: string,
            isChecked: boolean
        ) => onToggleTraitFilter(traitName, traitValue, isChecked),
    } as FiltersBarsProps;

    const [showFiltersSheet, setShowFiltersSheet] = useState(false);

    return (
        <>
            {isMobile && !showFiltersSheet && (
                <Button
                    position="fixed"
                    bottom={'5vh'}
                    left={0}
                    right={0}
                    zIndex={2000}
                    loadingText="Connecting..."
                    bg="primary"
                    color="white"
                    width="50vw"
                    borderRadius={'5rem'}
                    margin="0 auto"
                    onClick={() => setShowFiltersSheet(!showFiltersSheet)}
                >
                    <Flex position="relative" width="100%" height="100%">
                        <Flex
                            align="center"
                            justify="center"
                            position="absolute"
                            left={0}
                            right={0}
                            top={0}
                            bottom={0}
                        >
                            <Text textAlign="center">{`Filters${
                                activeFiltersCount > 0
                                    ? ` (${activeFiltersCount})`
                                    : ''
                            }`}</Text>
                        </Flex>
                    </Flex>
                </Button>
            )}

            <Flex align="center" justify="space-between" padding={'0 2rem'}>
                <Box />
                <FormControl maxWidth={['40%', '10rem']}>
                    <FormLabel
                        htmlFor="grid-item-display-count-select"
                        fontSize=".8rem"
                    >
                        Display Per Row
                    </FormLabel>
                    <Select
                        id="grid-item-display-count-select"
                        variant="outline"
                        size="sm"
                        value={gridItemDisplayCount}
                        onChange={(e) => onSelectDisplayItems(e.target.value)}
                        borderColor={useColorModeValue(
                            'rgba(0, 0, 0, 0.1)',
                            'rgba(255, 255, 255, 0.1)'
                        )}
                    >
                        {(isMobile ? [1, 2, 3] : [4, 5, 6]).map(
                            (gridItemCount) => (
                                <option
                                    value={gridItemCount}
                                >{`${gridItemCount} NFTs`}</option>
                            )
                        )}
                    </Select>
                </FormControl>
            </Flex>
            <Flex
                margin={['2rem 0 0 0', '2rem 2rem 0 2rem']}
                direction="row"
                align="flex-start"
                justify="center"
                position="relative"
            >
                {genverseContract?.traitRarity?.attributesRarity?.length > 0 &&
                    (isMobile ? (
                        <G3BottomSheet
                            showSheet={showFiltersSheet}
                            onDismissCallback={() => setShowFiltersSheet(false)}
                        >
                            <Box position="relative">
                                <Box margin={[0, '5rem 0 10rem 0']}>
                                    <FiltersBar {...filtersBarProps} />
                                </Box>
                            </Box>
                        </G3BottomSheet>
                    ) : (
                        <Flex
                            position="sticky"
                            maxHeight="85vh"
                            overflowY="auto"
                            top={'1rem'}
                            // flex={1}
                            width={['100%', '20rem']}
                            marginRight="1rem"
                            direction="column"
                            align="flex-end"
                            justify="flex-start"
                        >
                            <FiltersBar {...filtersBarProps} />
                        </Flex>
                    ))}

                <Box flex={1}>
                    {loading && (
                        <G3LogoSpinner
                            containerProps={{
                                h: '20vh',
                            }}
                        />
                    )}

                    {!loading &&
                        ((tokens || [])?.length > 0 ? (
                            <PageNFTGrid
                                tokens={tokens as GenverseTokenType[]}
                                hasNextPage={hasNextPage}
                                loadNextPage={loadNextPage}
                                gridProps={{
                                    templateColumns: `repeat(${gridItemDisplayCount}, 1fr)`,
                                }}
                            />
                        ) : (
                            <Text
                                padding="5rem 1rem"
                                textAlign="center"
                            >{`No results found for selection :(`}</Text>
                        ))}
                </Box>
                {/*<Flex flex={1} />*/}
            </Flex>
        </>
    );
};
