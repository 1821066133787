import React, { useEffect, useState } from 'react';
import {
    Checkbox,
    Flex,
    Input,
    Text,
    useColorModeValue,
} from '@chakra-ui/react';
import { startCase } from 'lodash';
import { AiOutlineSearch } from 'react-icons/ai';
import Fuse from 'fuse.js';

const searchOptions = {
    isCaseSensitive: false,
    includeScore: true,
    shouldSort: true,
    // includeMatches: false,
    // findAllMatches: false,
    // minMatchCharLength: 1,
    // location: 0,
    threshold: 0.4,
    // distance: 100,
    useExtendedSearch: true,
    // ignoreLocation: false,
    // ignoreFieldNorm: false,
    keys: ['name'],
};

export default ({
    name,
    traits,
    onSelectTrait,
    selectedTraits,
}: {
    name: string;
    traits: {
        name: string;
        count: number;
        rarityPercentage: number;
    }[];
    selectedTraits: any;
    onSelectTrait: (
        traitName: string,
        traitValue: string,
        isChecked: boolean
    ) => void;
}) => {
    const [traitSearch, setTraitSearch] = useState('');
    const [filteredTraits, setFilteredTraits] = useState(traits);

    useEffect(() => {
        if (traitSearch?.length > 0) {
            const fuse = new Fuse(traits, searchOptions);
            setFilteredTraits(fuse.search(traitSearch).map(({ item }) => item));
        } else {
            setFilteredTraits(traits);
        }
    }, [traitSearch]);
    return (
        <>
            <Flex
                margin="1rem 0"
                border={useColorModeValue(
                    'solid 1px rgba(0, 0, 0, .2)',
                    'solid 1px rgba(255, 255, 255, .2)'
                )}
                align="center"
                borderRadius="0.375rem"
            >
                <AiOutlineSearch
                    style={{
                        margin: '0 .5rem',
                    }}
                />
                <Input
                    size="sm"
                    placeholder={`Search`}
                    border={0}
                    flex={1}
                    value={traitSearch}
                    onChange={(e) => setTraitSearch(e.target.value)}
                    onKeyPress={(e) => {
                        // if (e.key === 'Enter') onClickSearchTokenId();
                    }}
                    autoCapitalize="none"
                    autoComplete="none"
                />
            </Flex>
            {filteredTraits?.map(
                ({
                    name: traitValue,
                    count,
                    rarityPercentage,
                }: {
                    name: string;
                    count: number;
                    rarityPercentage: number;
                }) => (
                    <Flex
                        align="center"
                        width="100%"
                        justify="space-between"
                        marginBottom="1rem"
                    >
                        <Checkbox
                            flex={1}
                            display="flex"
                            w="100%"
                            colorScheme="purple"
                            isChecked={selectedTraits?.[name] === traitValue}
                            onChange={(e) =>
                                onSelectTrait(
                                    name,
                                    traitValue,
                                    e.target.checked
                                )
                            }
                        >
                            <Text
                                textAlign="left"
                                fontSize={'0.8rem'}
                            >{`${startCase(traitValue)}`}</Text>
                        </Checkbox>
                        <Text
                            minWidth="5rem"
                            textAlign="right"
                            marginLeft="1rem"
                            fontSize={'0.8rem'}
                            opacity={0.5}
                        >{`${count} ${
                            rarityPercentage
                                ? `(${rarityPercentage?.toFixed(2)}%)`
                                : ''
                        }`}</Text>
                    </Flex>
                )
            )}
        </>
    );
};
