import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Flex,
    Input,
    Tag,
    TagCloseButton,
    TagLabel,
    Text,
    useColorModeValue,
    useMediaQuery,
} from '@chakra-ui/react';
import { keys, parseInt, startCase } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FiltersBarsProps } from '../../../../../../../../types';
import { NumberParam, useQueryParams } from 'use-query-params';
import G3Button from '../../../../../general/G3Button';
import FiltersTraitsList from './components/FiltersTraitsList';

const QUERY_EXPANDED_TRAIT = 'expandedTrait';
export default ({
    traitAttributes,
    selectedTraits,
    onSelectTrait,

    currentTokenId,
    onSearchTokenId,
}: FiltersBarsProps) => {
    const [queryParams, setQueryParams] = useQueryParams({
        [QUERY_EXPANDED_TRAIT]: NumberParam,
    });
    const [tokenId, setTokenId] = useState(currentTokenId);

    useEffect(() => {
        setTokenId(currentTokenId);
    }, [currentTokenId]);

    const onClickSearchTokenId = () => onSearchTokenId(tokenId as number);
    const [isMobile] = useMediaQuery('(max-width: 768px)');

    return (
        <Flex
            {...(isMobile
                ? {}
                : {
                      background: useColorModeValue(
                          'rgba(0, 0, 0, 0.1)',
                          'rgba(218, 175, 255, 0.05)'
                      ),
                      backdropFilter: useColorModeValue(
                          'brightness(100%)',
                          'brightness(200%)'
                      ),
                  })}
            // border={useColorModeValue(
            //     'solid 1px rgba(0, 0, 0, .2)',
            //     'solid 1px rgba(255, 255, 255, .2)'
            // )}
            borderRadius="0.375rem"
            padding="1rem"
            direction="column"
            width="100%"
            // height={['70vh', 'unset']}
            // overflowY="scroll"
        >
            {keys(selectedTraits)?.length > 0 && (
                <Flex
                    direction="row"
                    wrap="wrap"
                    align="center"
                    marginBottom="0.5rem"
                >
                    {keys(selectedTraits).map((traitType: string) => (
                        <Tag
                            size={'md'}
                            key={`${traitType}-${selectedTraits?.[traitType]}`}
                            borderRadius="full"
                            variant="solid"
                            colorScheme={useColorModeValue(
                                'blackAlpha',
                                'whiteAlpha'
                            )}
                            margin="0 1rem .5rem 0"
                        >
                            <TagLabel
                                fontSize={'0.8rem'}
                                color={useColorModeValue('white', 'black')}
                            >
                                <Flex align="center">
                                    <Text marginRight="0.5rem">{`${startCase(
                                        traitType
                                    )}:`}</Text>
                                    <Text fontWeight="800">{`${startCase(
                                        selectedTraits?.[traitType] as string
                                    )}`}</Text>
                                </Flex>
                            </TagLabel>
                            <TagCloseButton
                                onClick={() => {
                                    onSelectTrait(
                                        traitType,
                                        selectedTraits[traitType],
                                        false
                                    );
                                }}
                            />
                        </Tag>
                    ))}
                </Flex>
            )}

            <Flex
                width="100%"
                border={useColorModeValue(
                    'solid 1px rgba(0, 0, 0, .2)',
                    'solid 1px rgba(255, 255, 255, .2)'
                )}
                padding=".5rem"
                align="center"
                borderRadius="0.375rem"
            >
                <Input
                    size="sm"
                    type="number"
                    placeholder="Token ID: e.g 1234"
                    border={0}
                    flex={1}
                    value={tokenId ? `${tokenId}` : ''}
                    onChange={(e) => setTokenId(parseInt(e.target.value))}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') onClickSearchTokenId();
                    }}
                    autoCapitalize="none"
                    autoComplete="none"
                />
                <G3Button
                    size="sm"
                    marginLeft=".5rem"
                    onClick={onClickSearchTokenId}
                >
                    Search
                </G3Button>
            </Flex>
            <Accordion
                allowToggle
                marginTop="2rem"
                defaultIndex={queryParams?.[QUERY_EXPANDED_TRAIT] as number}
                onChange={(expandedIndex) =>
                    setQueryParams({
                        [QUERY_EXPANDED_TRAIT]: expandedIndex as number,
                    })
                }
            >
                {traitAttributes.map(
                    ({
                        name,
                        traitCounts,
                    }: {
                        name: string;
                        traitCounts: {
                            name: string;
                            count: number;
                            rarityPercentage: number;
                        }[];
                    }) => (
                        <AccordionItem
                            borderColor={useColorModeValue(
                                'rgba(0, 0, 0, 0.1)',
                                'rgba(255, 255, 255, 0.1)'
                            )}
                        >
                            {({ isExpanded }) => (
                                <>
                                    <AccordionButton>
                                        <Text
                                            fontSize=".8rem"
                                            flex="1"
                                            textAlign="left"
                                            color={useColorModeValue(
                                                'black',
                                                'white'
                                            )}
                                        >
                                            {startCase(name)}
                                        </Text>
                                        <AccordionIcon
                                            color={useColorModeValue(
                                                'black',
                                                'white'
                                            )}
                                        />
                                    </AccordionButton>
                                    <AccordionPanel padding={0}>
                                        {isExpanded && (
                                            <FiltersTraitsList
                                                name={name}
                                                onSelectTrait={onSelectTrait}
                                                selectedTraits={selectedTraits}
                                                traits={traitCounts}
                                            />
                                        )}
                                    </AccordionPanel>
                                </>
                            )}
                        </AccordionItem>
                    )
                )}
            </Accordion>
        </Flex>
    );
};
