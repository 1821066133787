import React from 'react';
import {Flex, Heading, useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import useGenverseContract from '../../../../hooks/useGenverseContract';
import DataTable from 'react-data-table-component';
import ENSResolvedAddress from '../../../general/ENSResolvedAddress';
import G3LogoSpinner from '../../../general/G3LogoSpinner';

export default ({
    chain,
    contractAddress,
}: {
    chain: string;
    contractAddress: string;
}) => {
    const [isMobile] = useMediaQuery('(max-width: 768px)');

    const { genverseContract, loading } = useGenverseContract({
        chain,
        contractAddress,
    });

    const columns = [
        {
            name: 'Holder',
            width: '10rem',
            selector: (row: { address: string; count: number }) => (
                <ENSResolvedAddress walletAddress={row.address} />
            ),
        },
        {
            name: 'Owned',
            width: '8rem',
            selector: (row: { address: string; count: number }) => row.count,
            sortable: true,
        },
        {
            name: '% Supply',
            width: '8rem',
            selector: (row: { address: string; count: number }) =>
                genverseContract?.tokensCount
                    ? `${(
                          (row.count / genverseContract?.tokensCount) *
                          100
                      ).toFixed(2)}%`
                    : '',
            sortable: true,
        },
    ];

    const data = genverseContract?.top100Holders;

    const customStyles = {
        headRow: {
            style: {
                background: 'transparent',
            },
        },
        table: {
            style: {
                background: 'transparent',
            },
        },
        rows: {
            style: {
                background: 'transparent',
                fontSize: isMobile ? '0.9rem' : '1rem',
            },
        },
        headCells: {
            style: {
                background: 'transparent',
                color: useColorModeValue('black', 'white'),
                fontSize: isMobile ? '0.9rem' : '1rem',
            },
        },
        cells: {
            style: {
                color: useColorModeValue('black', 'white'),
                // paddingLeft: '8px', // override the cell padding for data cells
                // paddingRight: '8px',
            },
        },
        pagination: {
            style: {
                background: 'transparent',
                color: useColorModeValue('black', 'white'),
            },
            pageButtonsStyle: {
                color: useColorModeValue('black', 'white'),
                fill: useColorModeValue('black', 'white'),
                '&:disabled': {
                    color: useColorModeValue(
                        'rgba(0, 0, 0, 0.2)',
                        'rgba(255, 255, 255, 0.2)'
                    ),
                    fill: useColorModeValue(
                        'rgba(0, 0, 0, 0.2)',
                        'rgba(255, 255, 255, 0.2)'
                    ),
                },
            },
        },
    };

    return (
        <Flex
            height="100%"
            justify={'flex-start'}
            align="center"
            direction="column"
            width="100%"
            flexWrap="wrap"
            borderRadius="0.375rem"
            overflow="hidden"
            // margin={['0 1rem', 0]}
            background={useColorModeValue(
                'rgba(0, 0, 0, 0.1)',
                'rgba(218, 175, 255, 0.05)'
            )}
            backdropFilter={useColorModeValue(
                'brightness(100%)',
                'brightness(200%)'
            )}
            padding=".5rem 1rem"
        >
            <Heading
                size="md"
                textAlign={['center', 'left']}
                fontWeight="300"
                margin="1rem 0"
            >
                {`Holders`}
            </Heading>

            {loading && <G3LogoSpinner containerProps={{ h: '20vh' }} />}

            {!loading && !!data?.length && (
                <DataTable
                    columns={columns as any}
                    data={data}
                    customStyles={customStyles}
                    pagination
                />
            )}
        </Flex>
    );
};
